.Lollipop {
  svg {
    font-family: Roboto, sans-serif;

  }

  .download-btns { //TODO centralize
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    button {
      font-size: 0.7em !important;
      cursor: pointer;
    }
  }
}
