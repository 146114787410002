.SaveAsModal {
    &__window {
        min-width: 600px;
    }

    .input {
        display: flex;

        + .input {
            margin-top: $dims_padding * 0.5;
        }

        label {
            flex: 1 0 auto;
            vertical-align: middle;

            width: 18.71rem;
            padding: $dims_padding;
            margin: auto;

            text-align: left;
            font-size: $font_size-medium;
        }

        input {
            width: 27rem;
            padding: $dims_padding;

            font-size: $font_size-regular;

            border: 1px solid $color_neutral;
            border-radius: $box_border-radius;
        }
    }

    button {
        @include cc-button;
    }

}
