$indent: 115px;
.InfoModal {
  .ReactModal__window {
    width: 75%;
  }

  ul.info {
    list-style: none;
    padding: 0;

    li {
      display: flex;

      + li {
        margin-top: $dims_padding-large;
      }

      span {
        flex: 1 0 auto;
        font-weight: 400;
        font-size: $font_size-medium;
        text-align: left;
        border-radius: $box_border-radius--small;
        background: $color_lightest-blue;
        padding: $dims_padding;
      }
    }

  }

  label {
    flex: 0 0 120px;
    margin: auto;

    font-size: $font_size-medium;
  }

  .more-info {
    padding: 0.75em;
    border: none;
    background-color: transparent;
    margin-left: $indent;
    cursor: pointer;

    span {
      padding: 0.75em;
      vertical-align: super;
    }
  }

  .modal-buttons {
    align-items: right;
  }

  button.primary {
    margin: 0;
    margin-left: auto;
  }

  button.text {
    margin: auto;
    margin-left: 0 !important;
    font-size: $font_size-small !important;
  }

  .user-guide-scroll {
    height: calc(80vh - 200px);
    overflow-y: scroll;
    margin-left: $indent;
    font-size:1.4em;

    h3 {
      padding-left: 0;
    }
    >ul {
      list-style: none;
      padding: 0;

      li {

      }

    }
  }

}
