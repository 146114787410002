@import '../../core/ui';
@import '../../core/ui/framer';
@import 'core';

@mixin form-entry {
  display: flex;
  padding: $dims_padding * .8 0;

  label {
    @include entry-text;
    width: 180px;
    vertical-align: middle;
    text-align: right;
    margin: auto 0;
    margin-right: $dims_padding * 2;

    font-size: 1.75rem;
  }

  .read-only {
    @include entry-text;
    font-size: 1.75rem;
    color: #9c9c9c;

  }

  col {
    display: flex;
    min-width: 200px;
  }

  select, input {
    @include form-input;
    width: 380px;
  }
}
