@import '../core';
.Swatch {
  display: inline-block;
  border-top: 1px solid gray;
  background-color: red;
  width: 12px;
  height: 12px;
}
.NameColorInput {
  .SwatchPicker {
    display: inline-block;
  }
}

.Button {
  @include cc-button;
  padding: $dims_padding;

  color: $color_font-light;
  background: $color_light-blue;
  border: none;
  outline: none;
  transition: background .2s;
  border-radius: $box_border-radius--medium;
  box-shadow: $box_shadow;


  &.primary {
      &:not(.disabled):hover {
        background: $color_blue;
      }
  }

  &.secondary {
    .text {
      color: $color_light-blue;
    }

    background: $color_bg-light;

    &:not(.disabled):hover {
      background: $color_bg-medium;
    }
  }

  &.text {
    background: none;
    display: inline-block;
    box-shadow: none;

    .text {
        color: $color_font-medium;
        font-size: $font_size-small;
        text-decoration: underline;
    }

    &:hover {
        .text {
            color: $color_font-dark;
        }
    }
  }

  &:not(.disabled):hover {
    cursor: pointer;

    .icon {
      stroke: $color_light-blue;
      transition: stroke .2s;
    }
  }

  &.disabled, &.disabled:hover {
    opacity: .6;
    cursor: normal;
    pointer-events: none;
  }

  .text {
    @include cc-button-text;
    display: flex;
    align-items: center;
  }
}

