@import '../../../core/ui/framer';
@import '../../../core/ui';
@import '../core';

body, html {
  @include u-s-e-01;
  font-family: "Inter", sans-serif;
  font-size: 1.5em;
}

h3 {
  font-size: 1.5em;
  padding-left: 0.25em;
}

.MainLayout {
  .OutputsPanel {
    @include fixed-layout;

    .content {
      h2 {
        @include chart-title;
        margin-bottom: 0.1em;
      }

      .total {
        @include chart-total-frame;
      }

      .total-title {
        @include chart-total-title;
        display: inline-block;
        margin-left: 1em;
        margin-top: 2px;
      }

      .total-val {
        @include chart-total-val;
        display: inline-block;
        margin-left: 1em;
        font-size: 14px;
      }
    }
  }

  .LandusePanel {
    @include fixed-layout;
  }

  .StreamsPanel {
    @include fixed-layout;
  }

  .SchemesBar {
    @include fixed-layout;
  }

}

.CarbonUsePicker {
  z-index: 1;
}

.InTheWeeds {
  width: 90%;

  padding: 8px 14px 8px 14px;
  background-color: #f2f5fa;
  border-radius: 20px;

  position: absolute;
  bottom: $mainPadding;
  left: $mainPadding;

  h3 {
    font-weight: 500;
    font-family: "Inter-Medium", "Inter", sans-serif;
    color: #050038;
    font-size: 14px;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: left;
  }
}


//used to match between titles and cards
$useElemWidth: 24%;
@mixin useElemHolder {
  width: 100%;
  padding: 10px;

}

.CarbonUseCardTitles {
  @include useElemHolder;

  .CarbonUseTitle {
    width: $useElemWidth;
    display: inline-block;
    text-align: center;
  }
}

.CarbonUseCard {
  min-height: 80px;
  @include useElemHolder;
  background-color: #ffffff;
  border-radius: 10px;

  border: 1px solid #dddddd;
  margin-bottom: 15px;

  overflow: hidden;

  .header {
    display: flex;

    .ref {
      height: 30px;
      padding: 10px;
      background-color: #f2f5fa;
      border-radius: 20px;
    }

    .name {
      //white-space: pre;
      font-weight: 500;
      font-style: normal;
      font-family: "Inter", sans-serif;
      color: #050038;
      font-size: 14px;
      padding-left: 0.6em;
      padding-top: 0.3em;
    }
  }

  &.previous {
    border: 3px dashed #050038;
  }

  &.selected {
    border: 2px solid #050038;
    background-color: $cc-color_bg-light;
  }


  .CarbonUseValue {
    display: inline-block;
    //background-color: $cc-color_bg-medium;
    width: $useElemWidth;
    padding: 0.25em 0.75em;
    margin-top: 0.3em;
    margin-right: 0.3em;
    background-color: #f2f5fa;
    overflow: hidden;
    border-radius: 10px;
    font-size: 13px;
  }
}

@mixin bc-nav {
  @include panel-title;
  display: inline-block;
  width: auto;
  margin-right: 0.3em;
}

.panelOuter {
  @include panel-frame;

  .PanelHeader {
    @include stack-grey;
    display: block;

    h2 {
      margin: 0;

      @include bc-nav;
      font-weight: lighter; //not sure why this is needed - but it displays differently in Framer

    }

    .breadcrumb {
      @include bc-nav;
    }

    p {
      @include panel-text;
      margin: 0;
    }
  }

  &.center {
    position: absolute;
    top: 0;
    bottom: $outerMargin;
    right: 0;
    left: $outerMargin;
  }

  &.right {
    position: absolute;
    top: 0;
    bottom: $outerMargin;
    right: $outerMargin;
    left: $outerMargin;
  }

  &.left {
    position: absolute;
    top: 0;
    bottom: $outerMargin;
    right: 0;
    left: $outerMargin;
  }

  display: flex;
  flex-flow: column;

  .panelInner {
    flex: 2;
    overflow: auto;
    padding: $mainPadding;

  }
}

.mainRibbonTop-holder {
  position: absolute;
  top: 0;
  left: 0;
  height: $toolbarHeight;
}

.mainRibbonTop {
  @include stack-blue;
  position: absolute;
  top: 5px;
  left: $outerMargin;
  right: 0;
  bottom: 0;
  width: auto; //override framer width
  h1 {
    @include carbon-conscience;
    font-family: 'Lustria', serif;
    font-size: 34px;
    padding-top: 0.15em;
    margin-left: 0.4em;
  }

  z-index: 1;
}

.toolbarSlideOut {
  @include main-ribbon-top;
  margin-top: 5px;
  position: absolute;
  left: 500px; //handled by code
  top: 5px;
  right: $outerMargin;
  bottom: 0;
  width: auto; //override framer width
  .toolButtons {
    padding-top: 2.5px;
    padding-left: 12.5px; //allow for tuck-under
  }

  background-position: calc(100% - 18px) 9px;
  background-size: 75px;
  background-repeat: no-repeat;
}


.IconButton {
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 5px;
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }
}

.ApplyCancel {
  position: fixed;
  bottom: $mainPadding + $outerMargin;
  right: $mainPadding + $outerMargin;
  display: flex;

  .Button {
    margin-right: 10px;
  }
}

.carbonLanduseEditorInput {
  .Button {
    margin-top: $mainPadding * 2;
    margin-bottom: $mainPadding * 2;
    background-color: $color_neutral;
  }
}

.SwatchPicker {
  $swatchSize: 32px;
  //width: $swatchSize;
  height: $swatchSize;

  .Swatch {
    @include swatch-frame;
    width: $swatchSize;
    height: $swatchSize;
  }

  .picker-open {
    z-index: 2;
  }
}


@import 'MenuBar';
@import 'LanduseItem';
@import 'LandusePanel';
@import './carbon/SummaryChart';
