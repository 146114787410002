@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.LoginMechanics {
  @media (orientation: portrait) {
    display: none;
  }
}

.LoginMobileWarning {
  @media (orientation: landscape) {
    display: none;
  }

  p {
    background: #e3e3e3;
    border-radius: $box_border-radius--medium;

    padding: 1em;
    font-size: 1.2em;
    max-width: 85vw !important;
  }
}

.Login {
  $_max-width: 25.429rem;

  @media (orientation: landscape) {
    display: flex;
  }
  justify-content: center;

  height: 100vh;

  background: #F2F5FA;

  > div {
    flex: 1 0 50%;

    margin: auto;

    text-align: center;
  }

  .forms {
    max-width: 610px;
  }

  .login-panel {
    max-width: $_max-width * 2.66;
    padding: 0 0 50px;

    @media (orientation: portrait) {
      padding-top: 5vw;
      padding-bottom: 2vw;
    }
    margin: auto;

    background: #F2F5FA;
    border-radius: $box_border-radius--medium;

    h2 {
      max-width: $_max-width;
      margin: auto;
      margin-bottom: $dims_padding;

      font-size: $font_size-medium;
      font-weight: 500;
      text-align: left;
    }

    h3 {
      max-width: 50%;
      margin: $dims_padding-large auto $dims_padding-large * 3;

      font-size: $font_size-xlarge;
      @media (orientation: portrait) {
        margin-top: 0;
        font-size: 3.2rem;
      }
      font-weight: 500;

      &:after {
        content: 'BETA';
        display: block;
        position: absolute;
        right: -1em;
        top: 4.5em;
        @media (orientation: portrait) {
          top: 0.5em;
          right: -3em;
        }
        font-size: $font-size_small;
        font-family: 'Inter';
        padding: .3em .6em;
        font-weight: 500;
        background: $color_light-blue;
        border-radius: 5px;
        color: $color_font-light;
      }
    }

    p {
      max-width: $_max-width;
      margin: auto;
      margin-bottom: $dims_padding-large;

      color: $color_font-medium;
      font-weight: 400;
      text-align: left;

      &.error {
        margin-top: $dims_padding;
      }
    }

    .input-field {
      width: 100%;
      max-width: $_max-width;
      margin: auto;
      margin-top: $dims_padding-large;

      text-align: left;

      &:first-of-type {
        margin-top: $dims_padding-large * 2;
      }

      &.hidden {
        display: none;
      }

      label {
        display: block;

        color: $color_font-medium;
        font-size: $font_size-regular;
        text-transform: uppercase;
        margin-bottom: .2rem;
      }

      input {
        width: 100%;
        padding: $dims_padding-large;

        font-size: $font_size-regular;
        background: $color_bg-light;
        border: 1.5px solid $color_font-medium;
        border-radius: $box_border-radius--small;
        outline: none;

        &:focus, &:active {
          border-color: $color_light-blue;
        }
      }

      button {
        position: absolute;
        top: 0;
        right: 0;

        height: auto;
      }
    }
  }

  .landing {
    .screens {
      width: 100%;
      max-width: 70vw;
      max-height: 90vh;

      @media (orientation: portrait) {
        margin: auto;

        @media screen and (max-height: 660px) {
          display: none;
        }
      }

      @media (orientation: landscape) {
        &:after {
          content: '';
          position: absolute;
          bottom: -6%;
          right: -4%;
          display: block;

          width: 35%;
          height: 50%;

          background: #66FFBD;
          border-radius: 25%;
          transform: skew(13deg);
        }
      }
    }

    img {
      position: absolute;
      z-index: 1;

      max-width: 100%;
      margin: auto;

      animation: fadeUp .8s ease-out forwards;
      animation-iteration-count: 1;
      opacity: 0;
      transform: translateY(15px);

      &:nth-of-type(1) {
        top: 0;
        left: 0;
        right: 0;

        width: 60%;
        animation-delay: .2s;
      }

      &:nth-of-type(2) {
        top: 50%;
        width: 38%;
        left: 0%;
        animation-delay: .4s;
      }

      &:nth-of-type(3) {
        top: 22%;
        right: 6%;
        width: 35%;
        animation-delay: .6s;
      }

      &:nth-of-type(4) {
        width: 40%;
        bottom: 0%;
        right: 32%;
        animation-delay: 1s;
      }
    }
  }

  .change-form {
    margin-top: $dims_padding-large * 2;
  }

  .error {
    color: $color_red !important;

    label {
      color: $color_red !important;
    }

    input {
      border-color: $color_red !important;
    }
  }

  footer {
    @media (orientation: landscape) {
      position: absolute;
      bottom: $dims_padding-large * 2;
      left: $dims_padding-large * 2;

      height: $font_size-medium;

      a, img, svg, button {
        height: 100%;
        padding: 0;
      }
    }

    @media (orientation: portrait) {
      position: fixed;
      bottom: 0;
      left: 0;

      a.sasaki-logo {
        position: absolute;
        bottom: $dims_padding-large;
        left: $dims_padding-large;

        img {
          height: 22px;
        }
      }
      button {
        position: absolute;
        bottom: 5rem;
        left: 0;
      }
      a.button {
        position: absolute;
        bottom: 8rem;
        left: 0.6rem;
      }
    }

    img {
      opacity: 1;
      transition: opacity .3s;

      &:hover {
        opacity: .8;
      }
    }

    * + * {
      margin-left: $dims_padding;
    }

    button, .button {
      display: inline-flex;
      align-items: center;

      width: $font_size-medium;
      transition: width .3s;
      overflow: hidden;
      text-decoration: none;
      background: none;
      box-shadow: none;
      outline: none;
      border: none;
      cursor: pointer;

      svg {
        flex: 0 0 $font_size-medium;
        stroke: $color_font-dark;
      }

      span {
        flex: 1 0 15rem;
        top: 1px;
        opacity: 0;
        left: -3px;
        text-align: left;
        color: $color_font-dark;
        font-size: $font_size-small;
      }

      @media (orientation: portrait) {
        width: 15rem;
        transition: width .3s;

        svg {
          //stroke: $color_light-blue;
        }

        span {
          opacity: 1;
          left: 0;
          //color: $color_light-blue;
        }
      }

      @media (orientation: landscape) {
        span {
          transition: left .3s, opacity .2s;
        }

        &:hover {
          width: 15rem;
          transition: width .3s;

          svg {
            stroke: $color_light-blue;
          }

          span {
            opacity: 1;
            left: 0;
            color: $color_light-blue;
          }
        }
      }


    }
  }

  .user-guide {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    height: 100vh;
    width: 100vw;

    opacity: 0;
    pointer-events: none;
    transition: opacity .5s;

    &-gutter {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      background: rgba(5, 0, 56, .4);
    }

    &.active {
      opacity: 1;
      pointer-events: all;
      transition: opacity .5s;

      .user-guide-window {


        @media (orientation: portrait) {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
        @media (orientation: landscape) {
          right: 0;
          transition: right .5s;
        }
      }
    }

    &-window {
      position: absolute;
      top: 0;
      right: -100%;
      z-index: 2;
      display: flex;
      flex-direction: column;

      width: 100rem;
      height: 100vh;

      background: $color_bg-light;
      transition: right .5s;
      border-radius: $box_border-radius--medium 0 0 $box_border-radius--medium;

      header {
        text-align: left;
        padding: $dims_padding-large;

        color: $color_light-blue;
        font-size: $font_size-large;
        @media (orientation: portrait) {
          font-size: 2rem;
        }
        font-weight: 400;

        background: $color_lightest-blue;
      }

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }

      .content {
        padding: $dims_padding * 2;
        overflow-y: auto;
        @media (orientation: portrait) {
          width: 100vw;
        }
      }
    }
  }

  button, .Button {
    &.primary {
      display: flex;
      margin: auto;
      align-items: center;
      font-weight: 500;
      font-size: $font_size-medium;

      margin-top: $dims_padding-large * 2;

      svg {
        margin-left: $dims_padding;
        stroke: $color_font-light !important;
      }
    }

    &.text {
      padding: 0;
      color: $color_light-blue;
      font-weight: 500;
      font-size: $font-size_regular;
      background: none;
      text-decoration: none;

      &:hover {
        color: $color_blue;
      }

      &:active, &:focus {
        text-decoration: underline;
      }
    }
  }

  .user-guide .col {
    display: flex;

    color: #43537C;
    font-size: 20px;
    @media (orientation: portrait) {
      font-size: 16px;
    }
    line-height: 1.5em;
    font-weight: 500;
    background: $color_lightest-blue;
    text-align: left;
    border-radius: 10px;

    p {
      margin: 0;
      padding: $dims_padding * 3 $dims_padding * 2;
    }

    p:nth-of-type(2) {
      flex: 1 0 65%;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5em;
    }
  }

  .accordion {
    list-style: none;
    padding: 0;
    text-align: left;

    > li {
      margin: $dims_padding-large auto;
      border-radius: 10px;
      box-shadow: $box_shadow;
      padding: $dims_padding-large $dims_padding-large * 2 $dims_padding-large $dims_padding-large;
      height: 54px;
      overflow: hidden;
      transition: height .5s;

      &.open {
        height: auto;

        h3:before {
          transform: rotate(-45deg);
        }
      }
    }

    h3 {
      cursor: pointer;
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      font-family: 'Inter';
      display: flex;

      span {
        flex: 1 0 auto;
        width: auto;
        border-bottom: 1px solid $color_bg-medium;
        padding-bottom: $dims_padding-large;
      }

      &:before {
        content: '';
        display: inline-block;
        margin-right: .5em;
        top: 2px;
        width: 1em;
        height: 1em;
        background-image: url(../assets/plus-icon.svg);
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(0deg);
        transition: transform .3s;
      }
    }

    .accordion-content {
      @media (orientation: landscape) {
        padding-left: 34px;
      }
    }

    h4 {
      font-size: $font_size-regular;
      margin-bottom: 0;
    }

    p {
      font-size: $font_size-regular;
    }

    li {
      font-size: $font_size-regular;

    }
  }

  button.close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    background: white;
    border: none;
    box-shadow: $box_shadow;
    border-radius: $box_border-radius--medium;
    cursor: pointer;
  }
}
