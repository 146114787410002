@import '../core';
@import '../framer.scss';

.StreamsPanel {
  h2 {
    @include chart-title;
    margin-bottom: 0.1em;
  }

  .CompositeItem {
    @include cc-bil-stack;
    display: block;
    width: 100%;
    height: auto;
    cursor: pointer;

    margin-top: 0.2em;
    margin-bottom: 0.3em;

    h4 {
      @include chart-title;
      //font-size: 0.8em;
      font-size: 14px;
      font-weight: bold;
      padding: 0.5em 1em;

      margin-top: 0;
      margin-bottom: 0.1em;
    }

    &.selected {
      border: 3px solid black;
    }
  }
}
