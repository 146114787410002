$box_border-radius: 5px;
$box_border-radius--small: $box_border-radius;
$box_border-radius--medium: $box_border-radius * 2;
$box_border-radius--large: $box_border-radius * 4;

$color_lightest-blue: #F2F5FA;
$color_light-blue: #6666FF;
$color_dark-blue: #050038;
$color_blue: #0000FF;
$color_red: #AD0D2E;

$color_bg-active: #6666FF;
$color_neutral: #7D7A96;
$color_bg-light: #FFFFFF; //match to Settings.tsx
$color_bg-medium: #EDEBFF;
$color_bg-dark: #050038;

$color_font-light: #FFFFFF;
$color_font-medium: #7D7A96;
$color_font-dark: #050038;

$box_shadow: 0 0 5px rgba($color_bg-dark, .4);

$dims_button-size: 4rem; // 40px
$dims_padding: .8rem; // 8px
$dims_padding-large: 1.5rem; // 15px

$font_size--base: 10px;
$font_size-xsmall: 1rem; // 10px
$font_size-small: 1.2rem; // 12px
$font_size-regular: 1.4rem; // 14px
$font_size-medium: 2rem; // 20px
$font_size-large: 2.4rem; // 24px
$font_size-xlarge: 4.8rem; // 48px


@mixin no-bg {
  pointer-events: none;

  * {
    pointer-events: all;
  }
}

@mixin fixed-layout {
  position: fixed;
  top: 0; //NOTE: these values managed by code...
  left: 0;
  width: 100vw;
  height: 100vh;
}

@mixin absolute-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


@mixin form-input {
  padding: $dims_padding;

  font-size: 1.2rem;

  border: 1.5px solid $color_neutral;
  border-radius: $box_border-radius;
}
