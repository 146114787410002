//@import '../../../core/ui';
@import '../../../core/ui/framer';
@import '../core';

.landuseList {//parent div for LanduseItems
  .Button {
    margin-top: $mainPadding + 8px;
  }
}

@mixin horizontalItem {
  @include cc-bil-stack;
  height: 40px;
  width: 100%;
  margin-top: $gap;

  .rightButtonArea {
    background-color: $light-grey;
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
    padding-top: 2px;
    //width: 100px;
    border-radius: 0px 10px 10px 0px;
  }
}

$gap: 15px;
.LanduseItem {
  @include horizontalItem;

  .Swatch {
    @include cc-bil-swatch;
    margin-left: -1px;//not sure why there is a 1 px gap without this...

  }

  .title {
    flex: 1.8;
    max-width: 40%;
    padding-left: 1em;
    h3 {
      @include cc-bil-title;
      cursor: pointer;
    }
  }

  .TweakableInput {
    @include cc-bil-material-stack;
    margin: 0 5px 0 5px;
  }

  .MaterialsInfo {
    @include cc-bil-material-stack;
    flex: 1;

    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: left;
    img {
      opacity: 0.35;
      width: 15px;
      height: 15px;
    }

    .num {
      @include cc-bil-material-num;
      vertical-align: middle;
      padding-left: 0.25em;
    }
    .perc {
      @include cc-bil-material-num;
      vertical-align: middle;
      opacity: 0.65;
      padding-left: 1em;
    }
    .text {
      @include cc-bil-material-text;
      vertical-align: middle;
      padding-left: 0.75em;
      padding-right: 0.75em;
    }
  }

  .areaInput {
    flex: 0.5;
    button {

    }
    input {
      width: 90px;
    }
  }
}
$componentsWidth: 700px;

.HorizontalMixer {
  width: $componentsWidth !important;//FIXME: something in base is overriding this, but can't figure out why
}

.carbonInputComponent {
  @include horizontalItem;
  width: $componentsWidth;
  .sharePerc {
    @include cc-bil-material-num;
    width: 50px;
    padding-left: 1em;
  }
  .displayInfo {
    @include cc-bil-material-num;
    white-space: normal;
    flex: 1;
    font-size: 12px;
    padding-left:1em;
    cursor: pointer;
  }
}
