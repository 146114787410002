@import 'core';

*, *:before, *:after {
    position: relative;
    box-sizing: inherit;
}

html, body {
  position: relative;
  margin: 0;
  color: $color_font-dark;
  font-size: $font_size--base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.Button.disabled {
    pointer-events: none;
}

.ReactModal {
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s;

    &.active {
        opacity: 1;
        pointer-events: all;
        transition: opacity .2s;

        .ReactModal__window {
            top: 50%;
            transition: top .3s;
        }
    }

    header {
        padding: $dims_padding-large;

        color: $color_light-blue;
        font-size: $font_size-large;
        font-weight: 400;

        background: $color_lightest-blue;
    }

    .content {
        padding: ($dims_padding-large * 2) $dims_padding-large $dims_padding-large;
    }

    &__gutter {
        background: rgba(5, 0, 56, .4);
    }

    &__window {
        top: 55%;

        padding: 0 !important;

        background: $color_bg-light;
        border-radius: $box_border-radius--medium;
        box-shadow: $box_shadow;
        overflow: hidden;
        transition: top .3s;
    }

    &__content {
        padding: 0;
    }

    &__close {
        display: none !important;
    }

    .modal-buttons {
        display: flex;
        align-items: right;
        text-align: right;
        margin-top: $dims_padding-large * 2;

        button {
            margin-left: auto;

            font-size: $font_size-medium;
            font-weight: 500;

            &.primary {
                margin-left: $dims_padding-large;
            }
        }
    }
}

@import "elements";
@import 'Login';
@import 'FileModal';
@import 'InfoModal';
@import 'SettingsModal';
@import 'SaveAsModal';
@import 'ToolBar';
@import "streams";
@import "HorizontalMixer";
@import "charts/Lollipop";
@import "charts/Popsicle";
@import "charts/ParallelPlot";
@import "connections/ConnectionsPanel";
