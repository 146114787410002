@import 'core';

.HorizontalMixer {
  width: 500px;
  $thumbSize: 12px;
  $thumbBorder: 2px;
  $totalHeight: 50px;
  $trackHeight: 38px;

  .track-label {
    text-align: center;
    padding-top: 4px;
  }

  .horizontal-slider {
    width: 100%;
    //max-width: 500px;
    height: $totalHeight;
    //border: 1px solid grey;

    .mixer-track {
      top: ($totalHeight - $trackHeight) * 0.5;
      height: $trackHeight;
    }

    .mixer-thumb {
      top: ($totalHeight - $thumbSize) * 0.5;
      width: $thumbSize;
      height: $thumbSize;
      line-height: $thumbSize - $thumbBorder * 2;
    }
  }

  .mixer-thumb {
    border-radius: $thumbSize * 0.5;
    font-size: 0.9em;
    text-align: center;
    background-color: black;
    color: white;
    cursor: pointer;
    border: $thumbBorder solid gray;
    box-sizing: border-box;
  }

  .mixer-thumb.active {
    background-color: grey;
  }

  .mixer-track {
    position: relative;
    background: #ddd;
    border: 1px solid gray;
    border-radius: 10px;
    overflow: hidden;

    .label {
      white-space: pre;
      padding-top: 0.4em;
      padding-left: 0.3em;
      padding-right: 0.3em;
      color: rgba(104, 114, 125, 0.47);
    }
    .perc {
      color: #aaaaaa;
    }
  }

  //.example-track.example-track-1 {
  //  background: #f00;
  //}
  //
  //.example-track.example-track-2 {
  //  background: #0f0;
  //}

  //.example-mark {
  //  width: 8px;
  //  height: 8px;
  //  border: 2px solid #000;
  //  background-color: #fff;
  //  cursor: pointer;
  //  border-radius: 50%;
  //  vertical-align: middle;
  //}
  //
  //.horizontal-slider .example-mark {
  //  margin: 0 calc(25px - 6px);
  //  bottom: calc(50% - 6px);
  //}

}

