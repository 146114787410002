@mixin connection-panel {

  .icon-info {
    margin-bottom: 1em;
    display: flex;

    img {
      flex-grow: 0; /* do not grow   - initial value: 0 */
      flex-shrink: 0; /* do not shrink - initial value: 1 */
      flex-basis: 3em; /* width/height  - initial value: auto */
    }
  }

  .footnote {
    font-size: 0.9em;
    color: #999999;

  }
}

.ConnectionsPanel {
  font-size: 1.3em;
}


.PaintTheMap {
  @include connection-panel;

  .qr-code {
    text-align: center;
  }

}

.TotalPix {
  @include connection-panel;
}

.tool-option {
  position: relative;
  margin-bottom: 12px;
  background-color: #ECEAFE;
  border-radius: 5px;
  padding: 5px 10px;

  h3 {
    padding-left: 0;
    margin-top: 6px;
  }

  button.Button {
    position: absolute;
    top: 3px;
    right: 3px;

    .text {
      font-size: 1.1em;
      padding: 0.5em;

    }

    //$size: 30px;
    //border-radius: calc($size / 2);
    //height: $size;
    //padding-top: 6px;
    //padding-left: calc($size/2)-3px;
    //padding-right: calc($size/2)-3px;
    //margin-right: 4px;

  }
}
