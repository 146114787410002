.ToolBar {

    .SaveOptions {
      $_top: $dims_button-size * 1.5;

      position: absolute;
      top: $_top * 1.3;
      left: $dims_button-size * 2;

      min-width: 250px;
      padding: $dims_padding-large;

      background: $color_bg-light;
      border-radius: $box_border-radius;
      box-shadow: $box_shadow;
      opacity: 0;
      pointer-events: none;
      transition: top .4s, opacity .3s;

      * {
          pointer-events: none;
      }

      &:before {
          content: '';
          position: absolute;
          top: -30%;
          display: block;

          height: 100%;
          width: 100%;

          pointer-events: none;
      }

      &.active, &:hover {
          top: $_top;

          opacity: 1;
          pointer-events: all;
          transition: top .4s, opacity .3s;

          &:before {
              pointer-events: all;
          }

          *:not(.disabled) {
              pointer-events: inherit;
          }
      }

      button {
          width: 100%;

          &:nth-of-type(2) {
              margin-top: $dims_padding;
          }
      }
    }

}
