@import '../../../../core/ui/framer';
@import '../../core';

.SummaryChart {
  .print-preview {
    margin-left: auto;
    margin-right: auto;
    width: 1130px;
    height: 65vh;
    overflow-y: auto;
  }
  .options {
    padding-left: 2em;

    display: flex;
    .entry {
      margin-right: 1.5em;
      label {
        font-size: 1.4em;
        padding-left: 0.25em;
        padding-right: 0.35em;
      }
    }
  }
  .modal-buttons {
    .action-buttons {
      flex:5;
      display: flex;
      .secondary {
        margin: 0 1em;
        flex:1;
      }
    }
    .space {
      flex:1;
    }
    .primary {
      flex:1;
    }
  }
}
