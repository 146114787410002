@import '../shared';

.LandusePanel {
  .entry {
    @include form-entry;
  }

  .get-started-help {
    padding: 0.5em 0.2em;
    font-size: 1.4em;
  }
}

.editComponentInner {
  @include absolute-fill;

  h2 {
    margin-top: 0.25em;
  }

  .column {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 1em;

    &:nth-child(0) {
      left: 0;
    }

    &:nth-child(1) {
      right: 0;

    }
  }


  select, input {
    @include form-input;
    width: 300px;
  }


  .scroll {
    right: 0;
    position: absolute;
    top: 0;
    bottom: 60px;
    overflow-y: scroll;
    overflow-x: hidden;

    .innerScroll {
      margin-bottom: 30px;
    }
  }

  .panelInner {
    overflow: hidden;
    background: blue;
  }
}

