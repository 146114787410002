$light-grey: #f2f5fa;

//\\//\\//\\ elements //\\//\\//\\
@mixin cc-button {
  box-sizing: border-box;
  width: auto; /* 165px */
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 0px 5px 0px var(--token-b3587589-e795-4413-8a35-ff52898f32f9, rgba(5, 0, 56, 0.4)) /* {"name":"SHADOW"} */
;
  background-color: var(--token-ffced7dd-c236-4838-9c87-2b9b39e38894, #6666ff);
  overflow: visible;
  border-radius: 10px;
}

@mixin cc-button-text {
  width: auto; /* 111px */
  height: auto; /* 18px */
  flex-shrink: 0;
  overflow: visible;
  white-space: pre;
  font-weight: 500;
  font-family: "Inter-Medium", "Inter", sans-serif;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 0.9;
  text-align: left;
}

@mixin entry-text {
  width: auto; /* 143px */
  height: auto; /* 24px */
  overflow: visible;
  white-space: pre;
  font-weight: 500;
  font-family: "Inter-Medium", "Inter", sans-serif;
  color: var(--token-44b0709d-368c-4f75-becb-7ec130dbc6b6, #050038) /* {"name":"00"} */
;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
}

//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\

@mixin stack-blue {
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 8px 0px 10px;
  box-shadow: 0px 0px 5px 0px var(--token-59aeb566-887a-4c25-b578-b5ca9d66f8fb, rgba(5, 0, 56, 0.5)) /* {"name":"SH"} */
;
  background-color: var(--token-c633fa39-6d31-4854-8c08-c5804440310e, #050038);
  border-radius: 5px;
}

@mixin stack-grey {
  box-sizing: border-box;
  height: auto; /* 97px */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  background-color: var(--token-425e6315-1a06-41a0-aaeb-7393ef6d65f9, #f2f5fa);
  overflow: visible;
  border-radius: 10px 10px 0px 0px;
}

@mixin panel-title {
  width: 100%;
  flex-shrink: 0;
  height: 36px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  color: var(--_, #050038);
  font-size: 24px;
  letter-spacing: 0px;
  text-align: left;
}

@mixin panel-text {
  width: 100%;
  flex-shrink: 0;
  //height: 21px;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  color: var(--token-8f26a77a-8933-44fe-b1aa-e9d01978f22c, #7d7a96) /* {"name":"03"} */
;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: left;
}

@mixin carbon-conscience {
  width: auto; /* 303px */
  height: auto; /* 44px */
  white-space: pre;
  color: var(--WHITE, #ffffff);
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}

@mixin u-s-e-01 {
  background-color: var(--token-2a136c26-a522-4075-962d-ae3c2423c0bf, #f2f5fa);
}

@mixin main-ribbon-top {
  width: 1890px;
  height: 40px;
  box-shadow: 0px 0px 5px 0px var(--token-1cd70f73-f8a5-4378-be96-0f3a2d6edcb3, rgba(5, 0, 56, 0.4)) /* {"name":"SH01"} */
;
  background-color: var(--token-65abf455-0aa4-4ded-bbff-9b96c658c4f6, #ffffff);
  overflow: visible;
  border-radius: 5px;
}

@mixin panel-frame {
  box-shadow: 0px 0px 5px 0px var(--token-b3587589-e795-4413-8a35-ff52898f32f9, rgba(5, 0, 56, 0.4)) /* {"name":"SHADOW"} */
;
  background-color: #ffffff;
  overflow: visible;
  border-radius: 10px;
}

//\\//\\//\\// button_IndivLanduse \\//\\//\\//\\

@mixin cc-bil-stack {
  box-sizing: border-box;
  width: 360px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--token-498f5f00-091f-442f-aed7-469a644be658, #ffffff);
  overflow: visible;
  border-radius: 10px;
  border: 1px solid var(--token-508b997a-dc82-4537-a91b-3409ae485b5e, #e5e5eb) /* {"name":"-04"} */
;
}

@mixin cc-bil-swatch {
  flex-shrink: 0;
  width: 15px;
  height: 40px;
  background-color: #e6ad28;
  overflow: visible;
  border-radius: 10px 0px 0px 10px;
}

@mixin cc-bil-title {
  width: auto; /* 44px */
  height: auto; /* 16px */
  flex-shrink: 0;
  overflow: visible;
  white-space: pre;
  font-weight: 500;
  font-family: "Inter-Medium", "Inter", sans-serif;
  color: #050038;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
}

@mixin cc-bil-tag-stack {
  box-sizing: border-box;
  width: auto; /* 80px */
  flex-shrink: 0;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: var(--token-d5c11306-f1b0-42e6-8685-7aab8278195c, #f2f5fa);
  overflow: hidden;
  border-radius: 50px;
}

@mixin cc-bil-material-stack {
  box-sizing: border-box;
  width: auto; /* 253px */
  flex-shrink: 0;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: var(--token-3d0fc8e8-dad7-403b-83e5-572ac1f9c1e4, #edebff);
  overflow: hidden;
  border-radius: 50px;
}

@mixin cc-bil-material-num {
  width: auto; /* 19px */
  height: auto; /* 14px */
  flex-shrink: 0;
  overflow: visible;
  white-space: pre;
  font-family: "Inter", sans-serif;
  color: var(--_, #504c73);
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 1;
}

@mixin cc-bil-material-text {
  width: auto; /* 194px */
  height: auto; /* 10px */
  flex-shrink: 0;
  overflow: visible;
  white-space: pre;
  font-family: "Inter", sans-serif;
  color: var(--_, #504c73);
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1;
  text-align: left;
}

@mixin swatch-frame {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  background-color: var(--token-3d0fc8e8-dad7-403b-83e5-572ac1f9c1e4, #edebff);
  overflow: visible;
  border-radius: 10px;
  //border: 1px solid #222;
}

/////////////////// charts

@mixin chart-title {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 500;
  font-family: "Inter-Medium", "Inter", sans-serif;
  color: var(--token-44b0709d-368c-4f75-becb-7ec130dbc6b6, #050038) /* {"name":"00"} */
;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.5;
  text-align: left;
}

@mixin chart-total-frame {
  height: 25px;
  background-color: var(--token-425e6315-1a06-41a0-aaeb-7393ef6d65f9, #f2f5fa);
  overflow: visible;
  border-radius: 5px;
}

@mixin chart-total-title {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  color: var(--token-8f26a77a-8933-44fe-b1aa-e9d01978f22c, #7d7a96) /* {"name":"03"} */
;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.5;
  text-align: left;
}

@mixin chart-total-val {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: 500;
  font-family: "Inter-Medium", "Inter", sans-serif;
  color: var(--_, #050038);
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.5;
}
