@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lustria&display=swap');
//@import '../../core/ui';
@import 'ui';

.Login {
    .logo  {
        display: block;
        margin: auto;
        padding: $dims_padding;
        border-radius: $box_border-radius--small;
    }

    h3 {
        font-family: 'Lustria';
    }
}
.SettingsModal {
    .entry {
        @include form-entry;
    }
}
